import React, { useState } from 'react';
import { SUPPORT_EMAIL } from '../config';
import './EmailSubscription.css';

const EmailSubscription = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('danger');
    const [showNotification, setShowNotification] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');
        setMessageType('danger');
        setShowNotification(false);

        if (!email) {
            setMessage('Please enter a valid email address.');
            setIsLoading(false);
            return;
        }

        try {
            // Log request details in development
            if (process.env.NODE_ENV === 'development') {
                console.group('📨 Subscription Request');
                console.log('Email:', email);
                console.groupEnd();
            }

            const response = await fetch('https://xsahbpmxaftczavjxbnc.supabase.co/functions/v1/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    source: 'landing_page'
                })
            });

            const data = await response.json();

            if (!response.ok) {
                if (response.status === 409) {
                    setMessage('This email is already subscribed!');
                    setMessageType('warning');
                } else if (response.status === 400) {
                    setMessage(data.error || 'Invalid email address.');
                    setMessageType('danger');
                } else {
                    setMessage(`An error occurred. Please try again later or contact ${SUPPORT_EMAIL}`);
                    setMessageType('danger');
                }
                setShowNotification(true);
                return;
            }

            // Clear form and show success notification
            setEmail('');
            setMessageType('success');
            setMessage(data.message || 'Successfully subscribed! Check your email for confirmation.');
            setShowNotification(true);

            // Auto-hide success notification after 5 seconds
            setTimeout(() => {
                if (messageType === 'success') {
                    setShowNotification(false);
                }
            }, 5000);

            // Log success in development
            if (process.env.NODE_ENV === 'development') {
                console.log('✅ Subscription successful:', data);
            }
        } catch (err) {
            console.error('Subscription Error:', err);
            setMessage(`An error occurred. Please try again later or contact ${SUPPORT_EMAIL}`);
            setMessageType('danger');
            setShowNotification(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className="section-dark" id="email-collection">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <h2 className="mb-4">Stay Updated!</h2>
                        <p className="mb-4">Get exclusive updates, tips, and early access to new features.</p>
                        <div className="subscription-container">
                            <form onSubmit={handleSubmit} className="d-flex gap-2" name="subscribe-form" autoComplete="on">
                                <label htmlFor="email-input" className="visually-hidden">Email Address</label>
                                <input 
                                    type="email"
                                    id="email-input"
                                    name="email-input"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    autoComplete="email"
                                    disabled={isLoading}
                                    aria-describedby={message ? "subscribe-message" : undefined}
                                    aria-invalid={messageType === 'danger'}
                                />
                                <button 
                                    type="submit" 
                                    id="subscribe-button"
                                    name="subscribe-button"
                                    className="btn btn-primary"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Subscribing...' : 'Subscribe'}
                                </button>
                            </form>
                            
                            {/* Dropdown Notification */}
                            <div 
                                className={`notification-dropdown ${showNotification ? 'show' : ''} alert-${messageType}`}
                                role="alert"
                                aria-live="polite"
                            >
                                {message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EmailSubscription;