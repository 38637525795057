import React from 'react';
import VideoPlayer from './components/VideoPlayer';
import EmailSubscription from './components/EmailSubscription';
import FingerPrintProgress from './components/FingerPrintProgress';
import DownloadButtons from './components/DownloadButtons';
import './App.css';

// Import images
import wavyBgTop from './assets/wavy-bg-top.png';
import wavyBgBottom from './assets/wavy-bg-bottom.png';
import chipsLogo from './assets/chips.png';

const App = () => {
    return (
        <div className="app">
            {/* Background elements */}
            <div className="bg-image" />
            <div className="bg-grid" />
            <div className="bg-gradient" />
            <img src={wavyBgTop} alt="" className="wavy-bg-top" />
            <img src={wavyBgBottom} alt="" className="wavy-bg-bottom" />
            
            {/* Navigation */}
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={chipsLogo} alt="CHIPS Logo" height="50" />
                    </a>
                    <div className="navbar-nav mx-auto">
                        <a href="#tokenomics" className="nav-link">Tokenomics</a>
                        <a href="#about" className="nav-link">About</a>
                        <a href="#features" className="nav-link">Features</a>
                        <a href="#problems" className="nav-link">Problems</a>
                        <a href="#roadmap" className="nav-link">Roadmap</a>
                        <a href="#whitepaper" className="nav-link">Whitepaper</a>
                        <a href="#buy" className="nav-link">Buy Chip</a>
                    </div>
                </div>
            </nav>

            {/* Main content sections with IDs */}
            <div className="container main-content">
                <section id="video">
                    <VideoPlayer />
                </section>
                <section id="subscribe">
                    <EmailSubscription />
                </section>
                <section id="progress">
                    <FingerPrintProgress />
                </section>
                <section id="download">
                    <DownloadButtons />
                </section>
            </div>

            {/* Footer */}
            <footer className="footer mt-5 py-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <img src={chipsLogo} alt="CHIPS Logo" height="30" />
                        </div>
                        <div className="col-md-4 text-center">
                            <p className="mb-0">&copy; 2024 CHIPS. All rights reserved.</p>
                        </div>
                        <div className="col-md-4 text-end">
                            <a href="/privacy" className="text-white-50 me-3">Privacy Policy</a>
                            <a href="/terms" className="text-white-50">Terms of Service</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default App; 