import React from 'react';
import './DownloadButtons.css';

const GooglePlayIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.609 2.292l8.879 8.879-8.879 8.879c-.396-.435-.609-1.073-.609-1.908V4.2c0-.835.213-1.473.609-1.908zm9.892 9.892l2.685 2.685c.396.396.396.792 0 1.188l-3.477 1.98L9.93 15.258l3.571-3.074zm-.396-1.188L9.93 7.922l2.779-2.779 3.477 1.98c.396.396.396.792 0 1.188l-2.685 2.685zM4.995 1.287l7.691 4.437-2.779 2.779-4.912-4.912c-.66-.66-.66-1.452 0-2.304zm0 20.606c-.66-.852-.66-1.644 0-2.304l4.912-4.912 2.779 2.779-7.691 4.437z" fill="currentColor"/>
    </svg>
);

const AppStoreIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.941 5.999c.903 0 1.892.56 2.593 1.473-.217.142-4.323 2.496-4.323 7.528 0 5.935 5.193 6.777 5.193 6.777-.044.14-.087.266-.144.392-.303.784-.714 1.562-1.253 2.229-.766.952-1.561 1.902-2.799 1.902-1.225 0-1.623-.719-3.029-.719-1.45 0-1.905.74-3.087.74-1.194 0-2.103-1.06-2.799-1.927-1.909-2.703-3.368-7.637-3.368-11.02 0-6.472 4.215-9.892 8.124-9.892 1.536 0 2.799 1.017 3.892 1.017zm-1.017-6c.174 1.407-.392 2.814-1.166 3.831-.786 1.017-2.06 1.796-3.313 1.796-.13-1.278.348-2.6 1.104-3.574.828-1.06 2.233-1.861 3.375-2.053z" fill="currentColor"/>
    </svg>
);

const DownloadButtons = () => {
    const scrollToSubscribe = () => {
        const subscribeSection = document.getElementById('email-collection');
        if (subscribeSection) {
            subscribeSection.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    };

    return (
        <div className="container text-center mt-4">
            <div className="download-buttons">
                {/* Google Play Button */}
                <a href="#" className="store-button google-play" onClick={(e) => {
                    e.preventDefault();
                    scrollToSubscribe();
                }}>
                    <div className="store-content">
                        <GooglePlayIcon />
                        <div className="store-text">
                            <span className="small-text">GET IT ON</span>
                            <span className="big-text">Google Play</span>
                        </div>
                    </div>
                </a>

                {/* App Store Button */}
                <a href="#" className="store-button app-store" onClick={(e) => {
                    e.preventDefault();
                    scrollToSubscribe();
                }}>
                    <div className="store-content">
                        <AppStoreIcon />
                        <div className="store-text">
                            <span className="small-text">Download on the</span>
                            <span className="big-text">App Store</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default DownloadButtons; 