// Supabase Configuration
export const SUPABASE_CONFIG = {
    url: process.env.REACT_APP_SUPABASE_URL,
    anonKey: process.env.REACT_APP_SUPABASE_ANON_KEY
};

// SendGrid Configuration
export const SENDGRID_CONFIG = {
    templateId: process.env.REACT_APP_SENDGRID_TEMPLATE_ID,
    apiKey: process.env.REACT_APP_SENDGRID_API_KEY,
    fromEmail: process.env.REACT_APP_SENDGRID_FROM_EMAIL
};

// Support Email
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

// API Configuration
export const API_CONFIG = {
    baseUrl: process.env.REACT_APP_API_URL || 'http://localhost:3000/api',
    edgeFunctionUrl: process.env.REACT_APP_SUPABASE_URL 
        ? `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/subscribe`
        : 'http://localhost:54321/functions/v1/subscribe'
};

// Email Service Configuration
export const EMAILJS_CONFIG = {
    serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    userId: process.env.REACT_APP_EMAILJS_USER_ID
};

// Validate required environment variables
const requiredVars = {
    'REACT_APP_SUPABASE_URL': process.env.REACT_APP_SUPABASE_URL,
    'REACT_APP_SUPABASE_ANON_KEY': process.env.REACT_APP_SUPABASE_ANON_KEY,
    'REACT_APP_SENDGRID_API_KEY': process.env.REACT_APP_SENDGRID_API_KEY,
    'REACT_APP_SENDGRID_TEMPLATE_ID': process.env.REACT_APP_SENDGRID_TEMPLATE_ID,
    'REACT_APP_SENDGRID_FROM_EMAIL': process.env.REACT_APP_SENDGRID_FROM_EMAIL
};

// Add debug logging in development
if (process.env.NODE_ENV === 'development') {
    console.group('🔧 Configuration');
    console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL);
    console.log('Edge Function URL:', API_CONFIG.edgeFunctionUrl);
    console.groupEnd();
}

Object.entries(requiredVars).forEach(([name, value]) => {
    if (!value) {
        console.error(`Missing required environment variable: ${name}`);
    }
}); 