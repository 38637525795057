import React from 'react';
import './VideoPlayer.css';

const VideoPlayer = () => {
    return (
        <div className="video-container">
            <div className="ratio ratio-16x9">
                <iframe 
                    src="https://www.youtube.com/embed/44wT8f9fcp0?autoplay=1&mute=1&loop=1&playlist=44wT8f9fcp0&controls=0&modestbranding=1&playsinline=1&rel=0&disablekb=1&iv_load_policy=3" 
                    title="CHIPS: Changing Habits Improves Personal Success" 
                    allow="autoplay" 
                    allowFullScreen 
                    style={{ pointerEvents: 'none', border: 0 }}
                />
            </div>
        </div>
    );
};

export default VideoPlayer; 