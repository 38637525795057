import React, { useState } from 'react';
import './FingerPrintProgress.css';

const FingerPrintProgress = () => {
    const [copiedChip, setCopiedChip] = useState(false);
    const [copiedCrumb, setCopiedCrumb] = useState(false);

    const chipFingerprint = "asset1lgehlasr25rwre05rfvz97p9m6tdmesrwhks3h";
    const crumbFingerprint = "asset1f9ch72lcm7s3rkafgu6j9rz33ej9s0epxwvfk0";

    const copyToClipboard = async (text, type) => {
        try {
            await navigator.clipboard.writeText(text);
            if (type === 'chip') {
                setCopiedChip(true);
                setTimeout(() => setCopiedChip(false), 2000);
            } else {
                setCopiedCrumb(true);
                setTimeout(() => setCopiedCrumb(false), 2000);
            }
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    return (
        <div className="container mt-4">
            <div className="fingerprint-container">
                <div className="fingerprint-row mb-4">
                    <div className="fingerprint-header">
                        <span className="fingerprint-label">Chip Fingerprint:</span>
                        <button 
                            className="copy-button"
                            onClick={() => copyToClipboard(chipFingerprint, 'chip')}
                        >
                            {copiedChip ? 'Copied!' : 'Copy'}
                        </button>
                    </div>
                    <div className="progress-wrapper">
                        <div className="progress" style={{ height: '60px' }}>
                            <div 
                                className="progress-bar bg-success" 
                                role="progressbar" 
                                style={{ width: '100%' }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                <span className="progress-text">{chipFingerprint}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fingerprint-row">
                    <div className="fingerprint-header">
                        <span className="fingerprint-label">Crumb Fingerprint:</span>
                        <button 
                            className="copy-button"
                            onClick={() => copyToClipboard(crumbFingerprint, 'crumb')}
                        >
                            {copiedCrumb ? 'Copied!' : 'Copy'}
                        </button>
                    </div>
                    <div className="progress-wrapper">
                        <div className="progress" style={{ height: '60px' }}>
                            <div 
                                className="progress-bar bg-info" 
                                role="progressbar" 
                                style={{ width: '100%' }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                <span className="progress-text">{crumbFingerprint}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FingerPrintProgress; 